import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { transparentize } from "polished"
import styled from "styled-components"
import { config, useSpring, animated } from "react-spring"
import Layout from "../components/layout"
import { Box, AnimatedBox } from "../elements"
import SEO from "../components/SEO"

const PBox = styled(AnimatedBox)`
    max-width: 1400px;
    margin: 0 auto;
`

const Content = styled(Box)<{ bg: string }>`
    background-color: ${(props) => transparentize(0.9, props.bg)};

    .gatsby-image-wrapper:not(:last-child) {
        margin-bottom: ${(props) => props.theme.space[10]};

        @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
            margin-bottom: ${(props) => props.theme.space[8]};
        }
    }
`

const Category = styled(AnimatedBox)`
    letter-spacing: 0.05em;
    font-size: ${(props) => props.theme.fontSizes[1]};
    text-transform: uppercase;
`

const Description = styled(animated.div)`
    max-width: 960px;
    letter-spacing: -0.003em;
    --baseline-multiplier: 0.179;
    --x-height-multiplier: 0.35;
    line-height: 1.58;
`

type PageProps = {
    data: {
        article: {
            title_detail: string
            seo_title: string
            color: string
            category: string
            intro: string
            desc: string
            slug: string
            parent: {
                modifiedTime: string
                birthTime: string
            }
            cover: {
                name: string
                childImageSharp: {
                    fluid: {
                        aspectRatio: number
                        src: string
                        srcSet: string
                        sizes: string
                        base64: string
                        tracedSVG: string
                        srcWebp: string
                        srcSetWebp: string
                    }
                    resize: {
                        src: string
                    }
                }
            }
            featuredImage: {
                name: string
                childImageSharp: {
                    fluid: {
                        aspectRatio: number
                        src: string
                        srcSet: string
                        sizes: string
                        base64: string
                        tracedSVG: string
                        srcWebp: string
                        srcSetWebp: string
                    }
                    resize: {
                        src: string
                    }
                }
            }
        }
        images: {
            nodes: {
                name: string
                childImageSharp: {
                    fluid: {
                        aspectRatio: number
                        src: string
                        srcSet: string
                        sizes: string
                        base64: string
                        tracedSVG: string
                        srcWebp: string
                        srcSetWebp: string
                    }
                }
            }[]
        }
    }
}

const Article: React.FunctionComponent<PageProps> = ({ data: { article, images } }) => {
    const categoryAnimation = useSpring({
        config: config.slow,
        from: { opacity: 0, transform: "translate3d(0, -30px, 0)" },
        to: { opacity: 1, transform: "translate3d(0, 0, 0)" },
    })

    const titleAnimation = useSpring({ config: config.slow, delay: 300, from: { opacity: 0 }, to: { opacity: 1 } })
    const descAnimation = useSpring({ config: config.slow, delay: 600, from: { opacity: 0 }, to: { opacity: 1 } })
    const imagesAnimation = useSpring({ config: config.slow, delay: 800, from: { opacity: 0 }, to: { opacity: 1 } })

    return (
        <Layout color={article.color}>
            <SEO
                pathname={article.slug}
                title={`${article.seo_title} | Kellie`}
                desc={article.desc}
                node={article.parent}
                banner={article.cover.childImageSharp.resize.src}
                individual
            />
            <PBox py={10} px={[6, 6, 8, 10]}>
                <Category style={categoryAnimation}>{article.category}</Category>
                <animated.h1 style={titleAnimation} dangerouslySetInnerHTML={{ __html: article.title_detail }} />

                {article.featuredImage ? (
                    <Img
                        alt={article.featuredImage.name}
                        key={article.featuredImage.childImageSharp.fluid.src}
                        fluid={article.featuredImage.childImageSharp.fluid}
                        style={{ margin: "1rem", maxHeight: "calc(100vh - 4rem)" }}
                        imgStyle={{ objectFit: "contain" }}
                    />
                ) : (
                    <Img
                        alt={article.cover.name}
                        key={article.cover.childImageSharp.fluid.src}
                        fluid={article.cover.childImageSharp.fluid}
                        style={{ margin: "1rem", maxHeight: "calc(100vh - 4rem)" }}
                        imgStyle={{ objectFit: "contain" }}
                    />
                )}
                <Description style={descAnimation}>
                    <div dangerouslySetInnerHTML={{ __html: article.desc }} />
                </Description>
            </PBox>
            <Content bg={article.color} py={10}>
                <PBox style={imagesAnimation} px={[6, 6, 8, 10]}>
                    {images.nodes
                        .filter((image) => !image.name.includes("-hero"))
                        .map((image) => (
                            <Img
                                alt={image.name}
                                key={image.childImageSharp.fluid.src}
                                fluid={image.childImageSharp.fluid}
                                style={{ margin: "1rem", maxHeight: "calc(100vh - 4rem)" }}
                                imgStyle={{ objectFit: "contain" }}
                            />
                        ))}
                </PBox>
            </Content>
        </Layout>
    )
}

export default Article

export const query = graphql`
    query ArticleTemplate($slug: String!, $images: String!) {
        article: articlesYaml(slug: { eq: $slug }) {
            title_detail
            seo_title
            color
            category
            intro
            desc
            slug
            parent {
                ... on File {
                    modifiedTime
                    birthTime
                }
            }
            cover {
                name
                childImageSharp {
                    fluid(quality: 95, maxWidth: 1200) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                    resize(width: 1200, height: 675, quality: 80) {
                        src
                    }
                }
            }
            featuredImage {
                name
                childImageSharp {
                    fluid(quality: 95, maxWidth: 1200) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                    resize(width: 1200, height: 675, quality: 80) {
                        src
                    }
                }
            }
        }
        images: allFile(filter: { relativePath: { regex: $images } }, sort: { fields: name, order: ASC }) {
            nodes {
                name
                childImageSharp {
                    fluid(quality: 95, maxWidth: 1200) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    }
`
